<template>
	<Spin class="pages" v-if="loading" fix>
		<Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
		<div>请求中</div>
	</Spin>
</template>
<script>
export default {
	name: 'mySpin',
	props: {
		loading: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			//
		};
	},
};
</script>
<style lang="less" scoped>
.demo-spin-icon-load {
	font-size: 30px !important;
	animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
	from {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	to {
		transform: rotate(360deg);
	}
}
</style>
