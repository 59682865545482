import { SET_USERINFO, SET_PERMS, SET_ROUTES } from '@/store/mutationsType';
import localstorage from '@/lib/localstorage';
import { localStorageKey } from '@/lib/localStorageKey';

export default {
    state: {
        userInfo: {},
        perms: [],
        routes: [],
    },
    mutations: {
        [SET_USERINFO](state, userInfo) {
            state.userInfo = userInfo;
        },
        [SET_PERMS](state, perms) {
            state.perms = perms;
        },
        [SET_ROUTES](state, routes) {
            state.routes = routes;
        },
    },
    actions: {
        setUserInfo({ commit }, userInfo) {
            localstorage.set(SET_USERINFO, userInfo);
            commit(SET_USERINFO, userInfo);
        },
        setPerms({ commit }, perms) {
            localstorage.set(localStorageKey.SET_PERMS, perms);
            commit(SET_PERMS, perms);
        },
        setRoutes({ commit }, routes) {
            localstorage.set(localStorageKey.SET_PERMS, routes);
            commit(SET_ROUTES, routes);
        },
    },
};
