//权限定义
export const permissionDef = {
    systemIndex: {
        value: 'SYSTEM_INDEX',
        title: '系统首页',
    },
    serviceManager: {
        value: 'SERVICE_MANAGER',
        title: '服务管理',
    },
    serviceList: {
        value: 'SERVICE_LIST',
        title: '服务列表',
    },
    orderManager: {
        value: 'ORDER_MANAGER',
        title: '订单管理',
    },
    orderList: {
        value: 'ORDER_LIST',
        title: '订单列表',
        children: {
            //按钮权限
            ORDER_LIST_DELETE: '删除',
            ORDER_LIST_QUERY: '查看',
            ASSIGN_SERVICE_PROVIDER: '分配服务商',
            ASSIGN_EMPLOYEE: '分配人员',
        },
    },
    monitorManager: {
        value: 'MONITOR_MANAGER',
        title: '检测管理',
    },
    monitorTemplate: {
        value: 'MONITOR_TEMPLATE',
        title: '检测模版',
    },
    monitorType: {
        value: 'MONITOR_TYPE',
        title: '检测分类',
    },
    monitorBill: {
        value: 'MONITOR_BILL',
        title: '检测单',
    },
    userManager: {
        value: 'USER_MANAGER',
        title: '用户管理',
    },
    userManagerList: {
        value: 'USER_MANAGER_LIST',
        title: '用户列表',
    },
    sysManager: {
        value: 'SYS_MANAGER',
        title: '系统设置',
    },
    sysManagerPay: {
        value: 'SYS_MANAGER_PAY',
        title: '支付设置',
    },
    sysManagerAccount: {
        value: 'SYS_MANAGER_ACCOUNT',
        title: '账号列表',
    },
    sysManagerRole: {
        value: 'SYS_MANAGER_ROLE',
        title: '角色管理',
    },
    sysManagerBannerList: {
        value: 'SYS_MANAGER_BANNER_LIST',
        title: 'Banner列表',
    },
    sysManagerIndexTypeConf: {
        value: 'SYS_MANAGER_INDEX_TYPE_CONF',
        title: '首页分类配置',
    },
    sysManagerHomeGdConf: {
        value: 'SYS_MANAGER_INDEX_GD_CONF',
        title: '首页广告配置',
    },
    goodManager: {
        value: 'GOOD_MANAGER',
        title: '商品管理',
    },
    goodList: {
        value: 'GOOD_MANAGER_LIST',
        title: '商品列表',
    },
    goodParams: {
        value: 'GOOD_PARAMS',
        title: '商品参数',
    },
    goodType: {
        value: 'GOOD_TYPE',
        title: '商品分类',
    },
    goodOrderManager: {
        value: 'GOOD_ORDER_MANAGER',
        title: '商品订单',
    },
    goodOrderList: {
        value: 'GOOD_ORDER_LIST',
        title: '全部订单',
    },
    afterSaleList: {
        value: 'AFTER_SALE_LIST',
        title: '售后管理',
    },
    afterSaleCauseList: {
        value: 'AFTER_SALE_CAUSE_LIST',
        title: '售后原因',
    },
    commonSetting: {
        value: 'COMMON_SETTING',
        title: '通用设置',
    },
    userFeedBackManager: {
        value: 'USER_FEEDBACK_MANAGER',
        title: '用户反馈',
    },
    userFeedBackList: {
        value: 'USER_FEEDBACK_LIST',
        title: '反馈列表',
    },
    financeManager: {
        value: 'FINANCE_MANAGER',
        title: '财务管理',
    },
    financeRefundLog: {
        value: 'FINANCE_REFUND_LOG',
        title: '退款记录',
    },
    financeOrderLog: {
        value: 'FINANCE_ORDER_LOG',
        title: '订单记录',
    },
    serviceProviderManager: {
        value: 'SERVICE_PROVIDER_MANAGER',
        title: '服务商管理',
    },
    serviceProviderList: {
        value: 'SERVICE_PROVIDER_LIST',
        title: '服务商列表',
    },
    informationManager: {
        value: 'INFORMATION_MANAGER',
        title: '资讯管理',
    },
    informationList: {
        value: 'INFORMATION_MANAGER_LIST',
        title: '资讯列表',
    },

    dataStatistics: {
        value: 'DATA_STATISTICS',
        title: '数据统计',
    },
    salesStatistics: {
        value: 'SALES_STATISTICS',
        title: '销售统计',
    },
    vipStatistics: {
        value: 'VIP_STATISTICS',
        title: '会员统计',
    },
    orderStatistics: {
        value: 'ORDER_STATISTICS',
        title: '销售统计',
    },
    marketingManager: {
        value: 'MARKETING_MANAGER',
        title: '营销管理',
    },
    discountCode: {
        value: 'DISCOUNT_CODE',
        title: '优惠码',
    },
};
