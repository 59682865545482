import Vue from 'vue';
import App from './App.vue';
import lib from './lib/lib';
import store from './store';
import router from './router';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import '@/view/common/common.css';
import * as util from '@/lib/util';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import CKEditor from 'ckeditor4-vue';
// Vue.use(CKEditor);

window.hm = util;

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(ViewUI);
Vue.use(lib);

console.log(Vue.$Message)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
