import { SET_SYSTEM } from '@/store/mutationsType';

export default {
	state: {
		systemInfo: {},
		menu: {
			name: null,
			title: null,
		},
	},
	mutations: {
		[SET_SYSTEM](state, systemInfo) {
			state.systemInfo = systemInfo;
		},
	},
	actions: {
		setSystemInfo({ commit }, systemInfo) {
			commit(SET_SYSTEM, systemInfo);
		},
	},
};
