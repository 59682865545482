import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import localstorage from '@/lib/localstorage';
import { routes, main, getPermissions, filterAsyncRouter } from '@/router/routes';
import { Notice } from 'view-design';
import Store from '@/store';
import { SET_ROUTES } from '@/store/mutationsType';
import cloneDeep from 'lodash/cloneDeep';
import { localStorageKey } from '@/lib/localStorageKey';

Vue.use(Router);

let router = new Router({
    routes,
});

const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err);
};

const whiteList = ['/login'];

NProgress.configure({ showSpinner: true });

router.beforeEach((to, from, next) => {
    NProgress.start();

    let goBackLogin = () => {
        Notice.error({
            title: '系统提示',
            desc: '会话超时，请重新登录',
        });

        localstorage.clear();
        clearRouter();
        next('/login');

        if (from.path === '/login') {
            NProgress.done();
        }
    };

    if (to.path === '/exit') {
        localstorage.clear();
        clearRouter();
        next('/login');
    } else if (whiteList.includes(to.path)) {
        next();
    } else {
        let token = localstorage.get(localStorageKey.ACCESS_TOKEN_QILANG);
        let expires_at = localstorage.get(localStorageKey.EXPIRES_AT_QILANG);

        if (token && Object.keys(token).length > 0) {
            if (new Date(expires_at * 1000) > new Date()) {
                let perms = getPermissions();
                let permission = to.meta.permission || null;
                // console.log('router.beforeEach', to);

                if (permission != null && !perms.includes(permission)) {
                    goBackLogin();
                } else {
                    if (hm.isNotEmptyString(to.name) && hm.isNotNullObject(to.params)) {
                        let routeParams = to.params;
                        if (routeParams.create == 1) {
                            sessionStorage.removeItem(to.name);
                            delete routeParams.create;
                        } else {
                            sessionStorage.setItem(to.name, JSON.stringify(to.params || {}));
                        }
                    }
                    if (Store.state.user.routes.length == 0) {
                        clearRouter();
                        let cloneMain = cloneDeep(main);
                        let rs = filterAsyncRouter(cloneMain);
                        Store.commit(SET_ROUTES, rs);
                        router.addRoutes(rs);

                        next({ ...to, replace: true });
                    } else {
                        next();
                    }
                }
            } else {
                goBackLogin();
            }
        } else {
            goBackLogin();
        }
    }
    document.title = 'Kinglong琪朗';
});

router.afterEach(() => {
    // console.log('afterEach');
    NProgress.done();
});

function clearRouter() {
    Store.commit(SET_ROUTES, []);
    let newRouter = new Router({
        routes,
    });
    router.matcher = newRouter.matcher;
}

export default router;
