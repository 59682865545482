<template>
    <div class="relative width_100_percent height_100_percent">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //
        };
    },
    mounted() {
        //
    },
    methods: {
        //
    },
};
</script>
<style lang="less" scoped></style>
