(function () {
    if (!!!window.structuredClone) {
        window.structuredClone = function (data) {
            return JSON.parse(JSON.stringify(data));
        };
    }
})();

Promise.prototype.finally = function (cb) {
    let Pro = this.constructor;
    return this.then(
        val => Pro.resolve(cb()).then(() => val),
        reason =>
            Pro.resolve(cb()).then(() => {
                throw reason;
            })
    );
};

Date.prototype.Format = function (fmt) {
    const o = {
        'M+': this.getMonth() + 1, //月份
        'd+': this.getDate(), //日
        'H+': this.getHours(), //小时
        'm+': this.getMinutes(), //分
        's+': this.getSeconds(), //秒
        'q+': Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (let k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    return fmt;
};

(() => {
    let mlCla = ``;
    let mtCla = ``;
    let fontsizeCla = ``;
    let widthCla = ``;
    for (let start = 5; start <= 350; start += 5) {
        mlCla += `.ml_${start}{ margin-left: ${start}px; }`;
        mtCla += `.mt_${start}{ margin-top: ${start}px; }`;
    }
    for (let start = 10; start <= 100; start += 1) {
        fontsizeCla += `.fontsize_${start}{ font-size: ${start}px!important; }`;
    }
    for (let start = 5; start <= 500; start += 5) {
        widthCla += `.width_${start}{ width: ${start}px!important; }`;
    }
    if (document.all) {
        console.log('document.all');
        window.style = `${mlCla}${mtCla}${fontsizeCla}${widthCla}`;
        document.createStyleSheet('javascript:style');
    } else {
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `${mlCla}${mtCla}${fontsizeCla}${widthCla}`;
        document.getElementsByTagName('HEAD').item(0).appendChild(style);
    }

    if (!!!window.structuredClone) {
        window.structuredClone = function (data) {
            return JSON.parse(JSON.stringify(data));
        };
    }
})();

export function isNotEmptyString(str) {
    let _str = getParamType(str) === type_number ? str + '' : str;
    return !!_str && !!_str.trim();
}

export function isFunction(cb) {
    return !!cb && Object.prototype.toString.call(cb) === '[object Function]';
}

export function isNotNullObject(obj) {
    let type = Object.prototype.toString.call(obj);
    if (type === '[object Object]') {
        return !!obj && Object.keys(obj).length > 0;
    } else if (type === '[object Array]') {
        return !!obj && obj.length > 0;
    } else {
        return false;
    }
}

/**
 * 验证提交的数据是否不为空
 * @param data 格式：Object/Array ---- { value:值, tip: 为空时对应的提示 }
 * @returns Boolean
 */
export function validateForm(data = null) {
    return new Promise((resolve, reject) => {
        let isEmpty = false;

        if (!isNotNullObject(data)) {
            resolve();
        }
        let _data = [];
        let t = Object.prototype.toString.call(data);
        if (t === '[object Object]') {
            _data.push(data);
        } else if (t === '[object Array]') {
            _data = data;
        } else {
            resolve();
        }

        let errTxt = null;
        if (_data.length > 0) {
            for (let info of _data) {
                if (!isNotEmptyString(info.value)) {
                    isEmpty = true;
                    errTxt = info.tip || '请输入值';
                    break;
                }
            }
        }
        if (isEmpty) {
            console.log('errTxt', errTxt);
            reject(errTxt);
        } else {
            resolve();
        }
    });
}

/**
 * 验证提交的数据是否不为空
 * @param data 格式：Object/Array ---- { value:值, tip: 为空时对应的提示 }
 * @returns errTxt: String
 */
export function validateFormSync(data = null) {
    let isEmpty = false;

    if (!isNotNullObject(data)) {
        return null;
    }
    let _data = [];
    let t = Object.prototype.toString.call(data);
    if (t === '[object Object]') {
        _data.push(data);
    } else if (t === '[object Array]') {
        _data = data;
    } else {
        return null;
    }

    let errTxt = null;
    if (_data.length > 0) {
        for (let info of _data) {
            if (!isNotEmptyString(info.value)) {
                isEmpty = true;
                errTxt = info.tip || '请输入值';
                break;
            }
        }
    }
    return errTxt;
}

export function getTime(format = 'yyyyMMddHHmmss') {
    return new Date().Format(format);
}

export function createId(key = '') {
    return window.btoa(encodeURIComponent(key + Date.now() + Math.random().toString(16)));
}

export function randomText(num = 5) {
    let words = [];
    for (let i = 0; i < num; i++) {
        eval('words.push(' + '"\\u' + (Math.round(Math.random() * 20901) + 19968).toString(16) + '")'); //生成随机汉字
    }
    return words.join('');
}

export function randomTel() {
    let str = '1';
    let rs = '3456789';
    for (let i = 0; i < 10; i++) {
        let rt = rs.charAt(~~(Math.random() * rs.length));
        str += rt;
    }
    return str;
}

export function randomDate() {
    let month = Math.floor(Math.random() * 12) + 1;
    let daysInMonth = new Date(2023, month, 0).getDate();
    let day = Math.floor(Math.random() * daysInMonth) + 1;
    let date = new Date(2023, month - 1, day);
    return date;
}

export function dateAddDay(date, day) {
    if (Object.prototype.toString.call(date) === '[object Date]') {
        return new Date(date.getTime() + day * (24 * 60 * 60 * 1000)).Format('yyyy-MM-dd HH:mm:ss');
    }
    return date;
}

export const type_number = 'number';
export const type_string = 'string';
export const type_boolean = 'boolean';
export const type_array = 'array';
export const type_object = 'object';
export const type_function = 'function';
export const type_null = 'null';
export const type_undefined = 'undefined';
export const type_symbol = 'symbol';
export const type_date = 'date';
const paramDataTypeDef = {
    [type_number]: '[object Number]',
    [type_string]: '[object String]',
    [type_boolean]: '[object Boolean]',
    [type_array]: '[object Array]',
    [type_object]: '[object Object]',
    [type_function]: '[object Function]',
    [type_null]: '[object Null]',
    [type_undefined]: '[object Undefined]',
    [type_symbol]: '[object Symbol]',
    [type_date]: '[object Date]',
};

export function getParamType(param) {
    let type = null;
    for (let key in paramDataTypeDef) {
        if (paramDataTypeDef[key] === Object.prototype.toString.call(param)) {
            type = key;
            break;
        }
    }
    return type;
}

//是否为数字类型
export function isNumber(val) {
    return !isNaN(parseFloat(val)) && isFinite(val) && !(val + '').endsWith('.');
}

//是否为大于0的数字类型
export function isNumberGt0(val) {
    return !isNaN(parseFloat(val)) && isFinite(val) && !(val + '').endsWith('.') && val > 0;
}

//是否为正数数&&>=0
export function isPositiveInteger(val) {
    let is_number = isNumber(val);
    let is_pure = is_number && val >= 0 && val % 1 === 0;
    // console.log(`isPureNumber(${val}) = ${is_pure}`);
    return is_pure;
}

export function consoleJson(param) {
    console.log(JSON.stringify(param, null, '\t'));
}

export function getRandomColor() {
    return 'rgb(' + Math.round(Math.random() * 255) + ',' + Math.round(Math.random() * 255) + ',' + Math.round(Math.random() * 10) + ')';
}
