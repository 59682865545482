import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import system from '@/store/modules/system';
import user from '@/store/modules/user';
import getters from '@/store/getters';

export default new Vuex.Store({
	modules: {
		system,
		user,
	},
	getters,
});
