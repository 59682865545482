<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import Vue from 'vue';
import mySpin from '@/components/mySpin';
Vue.component('mySpin', mySpin);

export default {
    name: 'App',
    components: {
        //
    },
};
</script>

<style>
#app {
    width: 100%;
    height: 100vh;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    color: #2c3e50;
}
</style>
