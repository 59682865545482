import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue';
import { isNotNullObject, isNumber } from './util';
import localstorage from './localstorage';
import { getPermissions } from '@/router/routes';

Vue.filter('cny', function (value) {
    if (!!!value) {
        return '￥0';
    }
    if (!isNumber(value)) {
        return value;
    }
    let formatter = new Intl.NumberFormat('zh-Hans-CN', { style: 'currency', currency: 'CNY' });
    return formatter.format(value);
});

Vue.directive('btn', {
    bind(el, binding) {
        let value = binding.value;

        if (!!!value) {
            el.style.display = 'none';
        }
        let pers = getPermissions();
        if (!pers.includes(value)) {
            el.style.display = 'none';
        }
    },
});

const lib = Object.create(null);
lib.install = function (Vue) {
    Vue.mixin({
        computed: {
            ...mapGetters(['userInfo', 'systemInfo']),
            currentRoute() {
                return this.$route || {};
            },
        },
        data() {
            return {
                ajaxLoading: false,
                uploadVideoUrl: 'http://upload.i-lz.cn/upload',
                dateFormat: 'yyyy-MM-dd HH:mm',
            };
        },
        methods: {
            ...mapActions(['setRoutes']),
            exit() {
                // console.log('.exit');
                // this.goUrl('/exit');

                localstorage.clear();
                this.goUrl('/login');
                // if (process.env.NODE_ENV === 'development') {
                //     window.location.replace('http://localhost:8080');
                // } else {
                //     window.location.replace('https://ql.i-lz.cn');
                // }
            },
            goUrl(path, query = {}) {
                if (this.currentRoute.path === path) {
                    return;
                }
                this.$router.push({
                    path,
                    query,
                });
            },
            goName(name, params = {}) {
                this.$router.push({
                    name,
                    params,
                });
            },
            showAjaxLoading() {
                this.ajaxLoading = true;
            },
            hideAjaxLoading() {
                this.ajaxLoading = false;
            },
            setParamsInStorage() {
                localstorage.set(this.currentRoute.name + '_params', this.params);
            },
            getParamsInStorage() {
                let params = localstorage.get(this.currentRoute.name + '_params');
                if (isNotNullObject(params)) {
                    this.params = params;
                }
            },
            getRouteParams() {
                let currentRoute = this.currentRoute || {};
                let routeParams = currentRoute.params || {};
                if (!hm.isNotNullObject(routeParams)) {
                    // console.log('getRouteParams', currentRoute.name, sessionStorage.getItem(currentRoute.name));
                    routeParams = JSON.parse(sessionStorage.getItem(currentRoute.name) || '{}');
                }
                return routeParams || {};
            },
            routeIdInject() {
                let id = (this.currentRoute || {}).query.id || null;
                if (this.hasOwnProperty('id')) {
                    this.id = id;
                }
                if (this.hasOwnProperty('formData')) {
                    this.formData.id = id;
                }
                if (this.hasOwnProperty('isEdit')) {
                    this.isEdit = !!id;
                }
            },
            routeParamsInjectFormData(fields = []) {
                let routeParams = this.getRouteParams();
                if (hm.isNotNullObject(routeParams) && this.hasOwnProperty('formData')) {
                    for (let key in this.formData) {
                        this.formData[key] = routeParams[key];
                    }
                }
                if (this.hasOwnProperty('isEdit')) {
                    this.isEdit = !!((this.formData || {}).id || null);
                }
                fields.forEach(field => {
                    if (this.hasOwnProperty(field) && !!routeParams[field]) {
                        this[field] = routeParams[field];
                    }
                });
            },
            dataInjectDataList(res) {
                let { totalPageNum, list } = (res || {}).data || {};
                this.totalPageNum = totalPageNum;
                if (this.hasOwnProperty('dataList')) {
                    this.dataList = list || [];
                }
                if (this.params.pageNums > 1 && this.totalPageNum == 1) {
                    this.params.pageNums = 1;
                    this.hasOwnProperty('getList') && this.getList();
                }
            },
            dataInjectFormData(res) {
                let { data } = res || {};
                if (data.hasOwnProperty('row')) {
                    data = data.row || {};
                } else if (data.hasOwnProperty('user')) {
                    data = data.user || {};
                }
                if (hm.isNotNullObject(data) && this.hasOwnProperty('formData')) {
                    for (let key in this.formData) {
                        if (data.hasOwnProperty(key)) {
                            this.formData[key] = data[key];
                        }
                    }
                }
            },
            confirmDialog(txt) {
                return new Promise(resolve => {
                    this.$Modal.confirm({
                        title: '温馨提示',
                        content: txt || '确认吗？',
                        onOk: () => {
                            resolve(1);
                        },
                        onCancel: () => {
                            resolve(0);
                        },
                    });
                });
            },
            fadeAlert(txt) {
                this.$Message.success(txt || '操作成功');
            },
            fadeWarning(txt) {
                this.$Notice.warning({
                    title: '温馨提示',
                    desc: txt || '异常',
                });
            },
        },
    });
};

export default lib;
