import { localStorageKey } from './localStorageKey';

const localstorage = {
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get(key, defaultValue = {}) {
        let val = {};
        try {
            val = JSON.parse(localStorage.getItem(key) || '{}') || defaultValue;
        } catch (err) {
            val = {};
        }
        return val;
    },
    remove(key) {
        localStorage.removeItem(key);
    },
    clear() {
        for (let localKey in localStorageKey) {
            this.remove(localKey);
        }
        // localStorage.clear();
    },
};

export default localstorage;
