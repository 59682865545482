import contentPage from '@/view/content';
import Store from '@/store';
import localstorage from '@/lib/localstorage';
import { SET_PERMS } from '@/store/mutationsType';
import { permissionDef } from '@/lib/permissionDef';

export const pageMenus = [
    {
        path: '/systemIndex',
        name: 'systemIndex',
        component: () => import('@/view/systemIndex/index'),
        meta: { activeName: 'systemIndex', title: '系统首页', permission: permissionDef.systemIndex.value },
    },
    {
        path: '/serviceManager',
        name: 'serviceManager',
        component: contentPage,
        redirect: '/serviceList',
        meta: { title: '服务管理', permission: permissionDef.serviceManager.value },
        children: [
            {
                path: '/serviceList',
                name: 'serviceList',
                component: contentPage,
                redirect: '/serviceListShow',
                meta: { title: '服务列表', click: true, permission: permissionDef.serviceList.value },
                children: [
                    {
                        path: '/serviceListShow',
                        name: 'serviceListShow',
                        component: () => import('@/view/serviceManager/list'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/serviceAdd',
                        name: 'serviceAdd',
                        component: () => import('@/view/serviceManager/add'),
                        meta: { title: '新增/编辑' },
                    },
                ],
            },
        ],
    },
    {
        path: '/orderManager',
        name: 'orderManager',
        component: contentPage,
        meta: { title: '订单管理', permission: permissionDef.orderManager.value },
        children: [
            {
                path: '/orderListShow',
                name: 'orderListShow',
                component: contentPage,
                redirect: '/orderList',
                meta: { title: '订单列表', click: true, permission: permissionDef.orderList.value },
                children: [
                    {
                        path: '/orderList',
                        name: 'orderList',
                        component: () => import('@/view/orderManager/orderList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/orderDetail',
                        name: 'orderDetail',
                        component: () => import('@/view/orderManager/orderDetail'),
                        meta: { title: '订单详情' },
                    },
                ],
            },
        ],
    },
    {
        path: '/distribution',
        name: 'distribution',
        component: contentPage,
        meta: { title: '分销管理', permission: permissionDef.serviceList.value },
        children: [
            {
                path: '/distributionShow',
                name: 'distributionShow',
                component: contentPage,
                redirect: '/distributionList',
                meta: { title: '分销列表', click: true },
                
                children: [
                    {
                        path: '/distributionList',
                        name: 'distributionList',
                        component: () => import('@/view/distribution/distributionList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/distributionListAdd',
                        name: 'distributionListAdd',
                        component: () => import('@/view/distribution/distributionListAdd'),
                        meta: { title: '编辑' },
                    },
                ],
            },
            // {
            //     path: '/examineListShow',
            //     name: 'examineListnShow',
            //     component: contentPage,
            //     redirect: '/examineList',
            //     meta: { title: '审核列表', click: true },
                
            //     children: [
            //         {
            //             path: '/examineList',
            //             name: 'examineList',
            //             component: () => import('@/view/distribution/examineList'),
            //             meta: { title: '审核列表' },
            //         },
            //     ],
            // },
            {
                path: '/distributionsRulesShow',
                name: 'distributionsRulesShow',
                component: contentPage,
                redirect: '/distributionsRules',
                meta: { title: '分销规则', click: true },
                
                children: [
                    {
                        path: '/distributionsRules',
                        name: 'distributionsRules',
                        component: () => import('@/view/distribution/distributionsRules'),
                        meta: { title: '分销规则' },
                    },
                ],
            },
            {
                path: '/distributionStatic',
                name: 'distributionStatic',
                component: contentPage,
                redirect: '/distributionStatic',
                meta: { title: '分销统计', click: true },
                
                children: [
                    {
                        path: '/distributionStatic',
                        name: 'distributionStatic',
                        component: () => import('@/view/distribution/distributionStatic'),
                        meta: { title: '分销统计' },
                    },
                ],
            }
            // {
            //     path: '/withdrawalApplication',
            //     name: 'withdrawalApplication',
            //     component: contentPage,
            //     redirect: '/withdrawalApplication',
            //     meta: { title: '提现申请', click: true },
                
            //     children: [
            //         {
            //             path: '/withdrawalApplication',
            //             name: 'withdrawalApplication',
            //             component: () => import('@/view/distribution/withdrawalApplication'),
            //             meta: { title: '提现申请' },
            //         },
            //     ],
            // },
        ],
    },
    {
        path: '/monitorManager',
        name: 'monitorManager',
        component: contentPage,
        redirect: '/monitorTemplate',
        meta: { title: '检测管理', permission: permissionDef.monitorManager.value },
        children: [
            // {
            //     path: '/monitorTemplate',
            //     name: 'monitorTemplate',
            //     component: contentPage,
            //     redirect: '/monitorTemplateList',
            //     meta: { title: '检测模版', click: true, permission: permissionDef.monitorTemplate.value },
            //     children: [
            //         {
            //             path: '/monitorTemplateList',
            //             name: 'monitorTemplateList',
            //             component: () => import('@/view/monitorManager/template'),
            //             meta: { title: '列表' },
            //         },
            //         {
            //             path: '/monitorTemplateEdit',
            //             name: 'monitorTemplateEdit',
            //             component: () => import('@/view/monitorManager/templateEdit'),
            //             meta: { title: '编辑' },
            //         },
            //     ],
            // },
            {
                path: '/monitorType',
                name: 'monitorType',
                component: contentPage,
                redirect: '/monitorTypeList',
                meta: { title: '检测分类', click: true, permission: permissionDef.monitorType.value },
                children: [
                    {
                        path: '/monitorTypeList',
                        name: 'monitorTypeList',
                        component: () => import('@/view/monitorManager/monitorTypeList'),
                        meta: { title: '列表' },
                    },
                ],
            },
            {
                path: '/monitorBill',
                name: 'monitorBill',
                component: contentPage,
                redirect: '/monitorBillList',
                meta: { title: '检测单', click: true, permission: permissionDef.monitorBill.value },
                children: [
                    {
                        path: '/monitorBillList',
                        name: 'monitorBillList',
                        component: () => import('@/view/monitorManager/monitorBillList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/monitorEdit',
                        name: 'monitorEdit',
                        component: () => import('@/view/monitorManager/monitorEdit'),
                        meta: { title: '编辑' },
                    },
                    
                    {
                        path: '/monitorBillDetail',
                        name: 'monitorBillDetail',
                        component: () => import('@/view/monitorManager/billDetail'),
                        meta: { title: '详情' },
                    },
                ],
            },
        ],
    },
    {
        path: '/userManager',
        name: 'userManager',
        component: contentPage,
        meta: { title: '用户管理', permission: permissionDef.userManager.value },
        children: [
            {
                path: '/userManagerList',
                name: 'userManagerList',
                component: contentPage,
                redirect: '/userList',
                meta: { title: '用户列表', click: true, permission: permissionDef.userManagerList.value },
                children: [
                    {
                        path: '/userList',
                        name: 'userList',
                        component: () => import('@/view/userManager/userList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/userView',
                        name: 'userView',
                        component: () => import('@/view/userManager/userView'),
                        meta: { title: '详情' },
                    },
                ],
            },
        ],
    },
    {
        path: '/marketingManager',
        name: 'marketingManager',
        component: contentPage,
        meta: { title: '营销管理', permission: permissionDef.marketingManager.value },
        children: [
            {
                path: '/discountCodeManager',
                name: 'discountCodeManager',
                redirect: '/discountCodeList',
                component: contentPage,
                meta: { title: '优惠码', click: true, permission: permissionDef.discountCode.value },
                children: [
                    {
                        path: '/discountCodeList',
                        name: 'discountCodeList',
                        component: () => import('@/view/marketingManager/discountCodeList'),
                        meta: { title: '优惠码列表' },
                    },
                    {
                        path: '/discountCodeAdd',
                        name: 'discountCodeAdd',
                        component: () => import('@/view/marketingManager/discountCodeAdd'),
                        meta: { title: '新增/编辑优惠码' },
                    },
                ],
            },
        ],
    },
    {
        path: '/goodManager',
        name: 'goodManager',
        component: contentPage,
        redirect: '/goodList',
        meta: { title: '商品管理', permission: permissionDef.goodManager.value },
        children: [
            {
                path: '/goodListManager',
                name: 'goodListManager',
                redirect: '/goodList',
                component: contentPage,
                meta: { title: '商品库', click: true, permission: permissionDef.goodList.value },
                children: [
                    {
                        path: '/goodList',
                        name: 'goodList',
                        component: () => import('@/view/goodManager/goodList'),
                        meta: { title: '商品列表' },
                    },
                    {
                        path: '/goodAdd',
                        name: 'goodAdd',
                        component: () => import('@/view/goodManager/goodAdd'),
                        meta: { title: '商品信息' },
                    },
                ],
            },
            {
                path: '/goodParamsManager',
                name: 'goodParamsManager',
                redirect: '/goodParams',
                component: contentPage,
                meta: { title: '商品参数', click: true, permission: permissionDef.goodParams.value },
                children: [
                    {
                        path: '/goodParams',
                        name: 'goodParams',
                        component: () => import('@/view/goodManager/goodParams'),
                        meta: { title: '参数列表' },
                    },
                    {
                        path: '/goodParamsAdd',
                        name: 'goodParamsAdd',
                        component: () => import('@/view/goodManager/goodParamsAdd'),
                        meta: { title: '参数信息' },
                    },
                ],
            },
            {
                path: '/goodTypeManager',
                name: 'goodTypeManager',
                redirect: '/goodType',
                component: contentPage,
                meta: { title: '商品分类', click: true, permission: permissionDef.goodType.value },
                children: [
                    {
                        path: '/goodType',
                        name: 'goodType',
                        component: () => import('@/view/goodManager/goodType'),
                        meta: { title: '分类列表' },
                    },
                ],
            },
        ],
    },
    {
        path: '/goodOrderManager',
        name: 'goodOrderManager',
        component: contentPage,
        redirect: '/goodOrderListManager',
        meta: { title: '商品订单', permission: permissionDef.goodOrderManager.value },
        children: [
            {
                path: '/goodOrderListManager',
                name: 'goodOrderListManager',
                redirect: '/goodOrderList',
                component: contentPage,
                meta: { title: '全部订单', click: true, permission: permissionDef.goodOrderList.value },
                children: [
                    {
                        path: '/goodOrderList',
                        name: 'goodOrderList',
                        component: () => import('@/view/goodOrderManager/goodOrderList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/goodOrderView',
                        name: 'goodOrderView',
                        component: () => import('@/view/goodOrderManager/goodOrderView'),
                        meta: { title: '订单详情' },
                    },
                ],
            },
            {
                path: '/afterSaleManager',
                name: 'afterSaleManager',
                redirect: '/afterSaleList',
                component: contentPage,
                meta: { title: '售后管理', click: true, permission: permissionDef.afterSaleList.value },
                children: [
                    {
                        path: '/afterSaleList',
                        name: 'afterSaleList',
                        component: () => import('@/view/goodOrderManager/afterSaleList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/afterSaleView',
                        name: 'afterSaleView',
                        component: () => import('@/view/goodOrderManager/afterSaleView'),
                        meta: { title: '售后详情' },
                    },
                ],
            },
            {
                path: '/afterSaleCause',
                name: 'afterSaleCause',
                redirect: '/afterSaleCauseList',
                component: contentPage,
                meta: { title: '售后原因', click: true, permission: permissionDef.afterSaleCauseList.value },
                children: [
                    {
                        path: '/afterSaleCauseList',
                        name: 'afterSaleCauseList',
                        component: () => import('@/view/goodOrderManager/afterSaleCauseList'),
                        meta: { title: '列表' },
                    },
                ],
            },
            {
                path: '/commonSettingManager',
                name: 'commonSettingManager',
                redirect: '/commonSetting',
                component: contentPage,
                meta: { title: '通用设置', permission: permissionDef.commonSetting.value },
                children: [
                    {
                        path: '/commonSetting',
                        name: 'commonSetting',
                        component: () => import('@/view/goodOrderManager/commonSetting'),
                        meta: { title: '设置' },
                    },
                ],
            },
        ],
    },
    {
        path: '/userFeedBackManager',
        name: 'userFeedBackManager',
        component: contentPage,
        redirect: '/userFeedBackListManager',
        meta: { title: '用户反馈', permission: permissionDef.userFeedBackManager.value },
        children: [
            {
                path: '/userFeedBackListManager',
                name: 'userFeedBackListManager',
                component: contentPage,
                redirect: '/userFeedBackList',
                meta: { title: '反馈列表', click: true, permission: permissionDef.userFeedBackList.value },
                children: [
                    {
                        path: '/userFeedBackList',
                        name: 'userFeedBackList',
                        component: () => import('@/view/userFeedBackManager/userFeedBackList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/userFeedBackView',
                        name: 'userFeedBackView',
                        component: () => import('@/view/userFeedBackManager/userFeedBackView'),
                        meta: { title: '详情' },
                    },
                ],
            },
        ],
    },
    {
        path: '/financeManager',
        name: 'financeManager',
        component: contentPage,
        redirect: '/financeRefundLogManager',
        meta: { title: '财务管理', permission: permissionDef.financeManager.value },
        children: [
            {
                path: '/financeRefundLogManager',
                name: 'financeRefundLogManager',
                component: contentPage,
                redirect: '/refundOrderList',
                meta: { title: '退款记录', click: true, permission: permissionDef.financeRefundLog.value },
                children: [
                    {
                        path: '/refundOrderList',
                        name: 'refundOrderList',
                        component: () => import('@/view/financeManager/refundOrderList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/refundOrderDetail',
                        name: 'refundOrderDetail',
                        component: () => import('@/view/financeManager/refundOrderDetail'),
                        meta: { title: '详情' },
                    },
                    {
                        path: '/refundLogAnalysis',
                        name: 'refundLogAnalysis',
                        component: () => import('@/view/financeManager/refundLogAnalysis'),
                        meta: { title: '退款记录分析' },
                    },
                ],
            },
            {
                path: '/commissionList',
                name: 'commissionList',
                component: contentPage,
                redirect: '/refundOrderList',
                meta: { title: '佣金提现审核', click: true, permission: permissionDef.financeRefundLog.value },
                children: [
                    {
                        path: '/commissionList',
                        name: 'commissionList',
                        component: () => import('@/view/financeManager/commissionList'),
                        meta: { title: '列表' },
                    },
                ],
            },
            {
                path: '/financeSetting',
                name: 'financeSetting',
                component: contentPage,
                redirect: '/refundOrderList',
                meta: { title: '财务设置', click: true, permission: permissionDef.financeRefundLog.value },
                children: [
                    {
                        path: '/financeSetting',
                        name: 'financeSetting',
                        component: () => import('@/view/financeManager/financeSetting'),
                        meta: { title: '列表' },
                    },
                ],
            },
            {
                path: '/financeOrderLogManager',
                name: 'financeOrderLogManager',
                component: contentPage,
                redirect: '/financeOrderList',
                meta: { title: '订单记录', click: true, permission: permissionDef.financeOrderLog.value },
                children: [
                    {
                        path: '/financeOrderList',
                        name: 'financeOrderList',
                        component: () => import('@/view/financeManager/financeOrderList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/financeOrderDetail',
                        name: 'financeOrderDetail',
                        component: () => import('@/view/financeManager/financeOrderDetail'),
                        meta: { title: '详情' },
                    },
                    {
                        path: '/financeOrderAnalysis',
                        name: 'financeOrderAnalysis',
                        component: () => import('@/view/financeManager/orderLogAnalysis'),
                        meta: { title: '订单记录分析' },
                    },
                ],
            },
        ],
    },
    {
        path: '/serviceProvierManager',
        name: 'serviceProviderManager',
        component: contentPage,
        meta: { title: '服务商管理', permission: permissionDef.serviceProviderManager.value },
        children: [
            {
                path: '/serviceProviderListManager',
                name: 'serviceProviderListManager',
                component: contentPage,
                redirect: '/serviceProviderList',
                meta: { title: '服务商列表', click: true, permission: permissionDef.serviceProviderList.value },
                children: [
                    {
                        path: '/serviceProviderList',
                        name: 'serviceProviderList',
                        component: () => import('@/view/serviceProviderManager/serviceProviderList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/serviceProviderView',
                        name: 'serviceProviderView',
                        component: () => import('@/view/serviceProviderManager/serviceProviderView'),
                        meta: { title: '服务商详情' },
                    },
                    {
                        path: '/serviceProviderAdd',
                        name: 'serviceProviderAdd',
                        component: () => import('@/view/serviceProviderManager/serviceProviderAdd'),
                        meta: { title: '新增/编辑' },
                    },
                ],
            },
        ],
    },
    {
        path: '/informationManager',
        name: 'informationManager',
        component: contentPage,
        meta: { title: '资讯管理', permission: permissionDef.informationManager.value },
        children: [
            {
                path: '/informationManagerList',
                name: 'informationManagerList',
                component: contentPage,
                redirect: '/informationList',
                meta: { title: '资讯列表', click: true, permission: permissionDef.informationList.value },
                children: [
                    {
                        path: '/informationList',
                        name: 'informationList',
                        component: () => import('@/view/informationManager/informationList'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/informationAdd',
                        name: 'informationAdd',
                        component: () => import('@/view/informationManager/informationAdd'),
                        meta: { title: '新增/编辑' },
                    },
                ],
            },
        ],
    },
    {
        path: '/dataStatistics',
        name: 'dataStatistics',
        component: contentPage,
        meta: { title: '数据统计', permission: permissionDef.dataStatistics.value },
        children: [
            {
                path: '/salesStatisticsManager',
                name: 'salesStatisticsManager',
                component: contentPage,
                redirect: '/salesStatistics',
                meta: { title: '销售统计', permission: permissionDef.salesStatistics.value },
                children: [
                    {
                        path: '/salesStatistics',
                        name: 'salesStatistics',
                        meta: { title: '详情' },
                        component: () => import('@/view/dataStatistics/salesStatistics'),
                    },
                ],
            },
            {
                path: '/vipStatisticsManager',
                name: 'vipStatisticsManager',
                component: contentPage,
                redirect: '/vipStatistics',
                meta: { title: '会员统计', permission: permissionDef.vipStatistics.value },
                children: [
                    {
                        path: '/vipStatistics',
                        name: 'vipStatistics',
                        meta: { title: '详情' },
                        component: () => import('@/view/dataStatistics/vipStatistics'),
                    },
                ],
            },
            {
                path: '/orderStatisticsManager',
                name: 'orderStatisticsManager',
                component: contentPage,
                redirect: '/orderStatistics',
                meta: { title: '订单统计', permission: permissionDef.orderStatistics.value },
                children: [
                    {
                        path: '/orderStatistics',
                        name: 'orderStatistics',
                        meta: { title: '详情' },
                        component: () => import('@/view/dataStatistics/orderStatistics'),
                    },
                ],
            },
            {
                path: '/cgStatisticsManager',
                name: 'cgStatisticsManager',
                component: contentPage,
                redirect: '/cgStatistics',
                meta: { title: '测光统计', permission: permissionDef.orderStatistics.value },
                children: [
                    {
                        path: '/cgStatistics',
                        name: 'cgStatistics',
                        meta: { title: '详情' },
                        component: () => import('@/view/dataStatistics/cgStatistics'),
                    },
                ],
            },
        ],
    },
    {
        path: '/sysManager',
        name: 'sysManager',
        component: contentPage,
        meta: { title: '系统设置', permission: permissionDef.sysManager.value },
        children: [
            {
                path: '/paySettingManager',
                name: 'paySettingManager',
                component: contentPage,
                redirect: '/paySetting',
                meta: { title: '微信支付配置', permission: permissionDef.sysManagerPay.value },
                children: [
                    {
                        path: '/paySetting',
                        name: 'paySetting',
                        meta: { title: '配置详情' },
                        component: () => import('@/view/sysManager/paySetting'),
                    },
                ],
            },
            {
                path: '/sysManagerAccount',
                name: 'sysManagerAccount',
                component: contentPage,
                redirect: '/sysManagerAccountList',
                meta: { title: '账号列表', click: true, permission: permissionDef.sysManagerAccount.value },
                children: [
                    {
                        path: '/sysManagerAccountList',
                        name: 'sysManagerAccountList',
                        component: () => import('@/view/sysManager/account'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/sysManagerAccountAdd',
                        name: 'sysManagerAccountAdd',
                        component: () => import('@/view/sysManager/accountAdd'),
                        meta: { title: '新增/编辑' },
                    },
                ],
            },
            {
                path: '/functionalConfiguration',
                name: 'functionalConfiguration',
                component: contentPage,
                redirect: '/functionalConfiguration',
                meta: { title: '功能配置', click: true },
                children: [
                    {
                        path: '/functionalConfiguration',
                        name: 'functionalConfiguration',
                        component: () => import('@/view/sysManager/functionalConfiguration'),
                        meta: { title: '功能配置' },
                    }
                   
                ],
               
            },
            {
                path: '/messageManagement',
                name: 'messageManagement',
                component: contentPage,
                redirect: '/messageManagement',
                meta: { title: '消息管理', click: true },
                children: [
                    {
                        path: '/messageManagement',
                        name: 'messageManagement',
                        component: () => import('@/view/sysManager/messageManagement'),
                        meta: { title: '消息管理' },
                    }
                
                ],
            
            },
            {
                path: '/investigationList',
                name: 'investigationList',
                component: contentPage,
                redirect: '/investigationList',
                meta: { title: '问卷调查',click: true  },
                children: [
                    {
                        path: '/investigationList',
                        name: 'investigationList',
                        meta: { title: '问卷调查列表' },
                        component: () => import('@/view/sysManager/investigationList'),
                    },
                    {
                        path: '/investigationAdd',
                        name: 'investigationAdd',
                        meta: { title: '新增问卷调查' },
                        component: () => import('@/view/sysManager/investigationAdd'),
                    },
                ],
            },
            {
                path: '/sysManagerRole',
                name: 'sysManagerRole',
                component: contentPage,
                redirect: '/sysManagerRoleList',
                meta: { title: '角色管理', click: true, permission: permissionDef.sysManagerRole.value },
                children: [
                    {
                        path: '/sysManagerRoleList',
                        name: 'sysManagerRoleList',
                        component: () => import('@/view/sysManager/role'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/sysManagerRoleAdd',
                        name: 'sysManagerRoleAdd',
                        component: () => import('@/view/sysManager/roleAdd'),
                        meta: { title: '新增/编辑' },
                    },
                    {
                        path: '/sysManagerPermission',
                        name: 'sysManagerPermission',
                        component: () => import('@/view/sysManager/permission'),
                        meta: { title: '权限分配' },
                    },
                ],
            },
            {
                path: '/bannerManager',
                name: 'bannerManager',
                component: contentPage,
                redirect: '/bannerManagerList',
                meta: { title: 'Banner列表', click: true, permission: permissionDef.sysManagerBannerList.value },
                children: [
                    {
                        path: '/bannerManagerList',
                        name: 'bannerManagerList',
                        component: () => import('@/view/sysManager/banner'),
                        meta: { title: '列表' },
                    },
                    {
                        path: '/bannerAdd',
                        name: 'bannerAdd',
                        component: () => import('@/view/sysManager/bannerAdd'),
                        meta: { title: '新增/编辑' },
                    },
                ],
            },
            {
                path: '/indexTypeManager',
                name: 'indexTypeManager',
                component: contentPage,
                redirect: '/indexType',
                meta: { title: '首页分类配置', click: true, permission: permissionDef.sysManagerIndexTypeConf.value },
                children: [
                    {
                        path: '/indexType',
                        name: 'indexType',
                        meta: { title: '列表' },
                        component: () => import('@/view/sysManager/indexType'),
                    },
                    {
                        path: '/indexTypeAdd',
                        name: 'indexTypeAdd',
                        meta: { title: '新增/编辑' },
                        component: () => import('@/view/sysManager/indexTypeAdd'),
                    },
                ],
            },
            {
                path: '/homeGdManager',
                name: 'homeGdManager',
                component: contentPage,
                redirect: '/homeGd',
                meta: { title: '首页广告配置', click: true, permission: permissionDef.sysManagerHomeGdConf.value },
                children: [
                    {
                        path: '/homeGd',
                        name: 'homeGd',
                        meta: { title: '列表' },
                        component: () => import('@/view/sysManager/homeGd'),
                    },
                    {
                        path: '/homeGdAdd',
                        name: 'homeGdAdd',
                        meta: { title: '新增/编辑' },
                        component: () => import('@/view/sysManager/homeGdAdd'),
                    },
                ],
            },
        ],
    },
];

export const routes = [
    {
        path: '/exit',
        name: 'exit',
        meta: { title: '退出登录' },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/view/login/login'),
        meta: { title: '登录' },
    },
    {
        path: '/',
        redirect: '/index',
    },
    {
        path: '*',
        component: () => import('@/view/common/404'),
    },
];

export const main = [
    {
        path: '/index',
        name: 'index',
        children: pageMenus,
        component: () => import('@/view/index'),
        redirect: '/systemIndex',
    },
];

export function getPermissions() {
    let perms = Store.state.user.perms || [];
    if (!perms || perms.length <= 0) {
        perms = localstorage.get(SET_PERMS);
        if (perms && Object.keys(perms).length <= 0) {
            perms = [];
        }
    }
    return perms;
}

export function havePermission(per) {
    let pers = getPermissions();
    return pers.includes(per || '-99999999');
}

export function filterAsyncRouter(router) {
    let permissions = getPermissions();
    const accessedRouters = router.filter(route => {
        if (hasPermission(permissions, route)) {
            if ((route.children || []).length > 0) {
                route.children = filterAsyncRouter(route.children);
                if (route.children.length == 0) {
                    route.test = 'testtest...';
                }
            }
            return true;
        }
        return false;
    });
    // console.log('##############filterAsyncRouter', permissions, accessedRouters);
    return accessedRouters;
}

function hasPermission(permissions, route) {
    if (!!(route.meta || {}).permission) {
        const routePermissions = route.meta.permission;
        // console.log('##############routePermissions', routePermissions, route);
        return permissions.includes(routePermissions);
    }
    return true;
}
